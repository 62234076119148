import { AnimatePresence, motion } from "framer-motion"
import { useContext, useEffect, useState } from "react"
import Topbar from "../container/Topbar"
import { ScaleContext } from "../utils/Scale";

const baseStyle = {
    position: 'absolute',
    top: 0, left: 0,
    overflow: 'hidden',
    display: 'flex',
    flexFlow: 'column nowrap',
    alignItems: 'center',
    pointerEvents: 'auto'
}

const animation = {
    initial: {
        transform: 'translate(100%, 0)'
    },
    animate: {
        transform: 'translate(0%, 0)'
    },
    exit: {
        transform: 'translate(100%, 0)'
    },
    transition: {
        duration: 0.2,
        ease: "easeInOut"
    }
}

const Page = ({visible, background = 'var(--white)', heading, topbar = 'var(--dark)', style, onBack, onHome, children, width, height, paddingSides = 0, paddingBottom = 0, topNavigationEnabled = true}) => {
    const [styles, setStyles] = useState({})
    const {scale} = useContext(ScaleContext)

    useEffect(() => {
        setStyles({
            ...baseStyle,
            ...style,
            background: background,
            width: 'calc(' + width + 'px - ' + paddingSides * 2 + 'px)',
            height: 'calc(' + height + 'px - calc(35px * ' + scale + ') - ' + paddingBottom + 'px)',
            padding: 'calc(35px * ' + scale + ') ' + paddingSides + 'px ' + paddingBottom + 'px ' + paddingSides + 'px'
        })
    }, [style, background, width, height, paddingSides, paddingBottom])

    return <AnimatePresence>
        {visible &&
        <motion.div
            style={styles}
            initial={animation.initial}
            animate={animation.animate}
            exit={animation.exit}
            transition={animation.transition}
        >
            <Topbar paddingSides={0} color={topbar} onBack={onBack} onHome={onHome} navigationEnabled={topNavigationEnabled}>{heading ? heading[0].toUpperCase() + heading.slice(1) : ''}</Topbar>
            {children}
        </motion.div>
        }
    </AnimatePresence>
}

export default Page