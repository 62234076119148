import { createContext } from "react"

// Context

const ScaleContext = createContext({scale: 1})

const Scale = ({scale, scaleW, children}) => {
    return (
        <ScaleContext.Provider value={{scale: scale, scaleW: scaleW}}>
            {children}
        </ScaleContext.Provider>
    )
}

export default Scale

export { ScaleContext }