import Icon, { Delete, Email, Eye, Profile, ResetResponses } from "../components/Icon"
import Card from "../cards/Card"
import { useContext, useEffect, useState } from "react"
import Text from "../components/text/Text"
import Button, { ButtonThemes } from "../components/Button"
import { ScaleContext } from "../utils/Scale";
import { arrayUnion, doc, Timestamp, updateDoc } from 'firebase/firestore';
import { db, functions } from "../utils/firebase";
import Input from "../components/Input"
import { motion, useAnimationControls } from "framer-motion"
import { useHttpsCallable } from "react-firebase-hooks/functions"
import Logo from '../images/PG Icon.png'

const Bottombar = ({ viewport, provocation, data, forceActive, onDelete }) => {
    const [showResetResponses, setShowResetResponses] = useState(false)
    const [showRemoveResponses, setShowRemoveResponses] = useState(false)
    const [showAllowCommunity, setShowAllowCommunity] = useState(false)
    const [showEmailResponses, setShowEmailResponses] = useState(false)
    const { scale } = useContext(ScaleContext)
    const [isForced, setIsForced] = useState(false)
    const [time, setTime] = useState(new Date())
    const [email, setEmail] = useState('')
    const controls = useAnimationControls()
    const [emailFocused, setEmailFocused] = useState(false)
    const [sendCSV, sendCSVLoading] = useHttpsCallable(functions, 'sendCSV')

    useEffect(() => {
        const interval = window.setInterval(() => {
            setTime(new Date())
        }, 1000)
        return () => clearInterval(interval)
    }, [])

    useEffect(() => {
        if (!!data && !!forceActive && !!provocation) {
            const forceActiveData = data.dev ? { ...forceActive.dev } : { ...forceActive.live }
            const forced = provocation === forceActiveData.id && forceActiveData.end > new Date()
            if (forced !== isForced) setIsForced(forced)
        }
    }, [time]);

    const hideAllCards = () => {
        setShowAllowCommunity(false)
        setShowRemoveResponses(false)
        setShowResetResponses(false)
        setShowEmailResponses(false)
        setEmailFocused(false)
    }

    const resetResponses = () => {
        if (!!provocation) {
            updateDoc(doc(db, 'provocations', provocation), { resetResponses: arrayUnion(Timestamp.now()) })
                .then(() => {
                    setShowResetResponses(false)
                })
        }
    }

    const toggleForceActive = () => {
        if (!!data) {
            updateDoc(
                doc(db, 'users', data.dev ? 'IdgI7pMAzrfR7EVTurnqgyGJ7te2' : 'lmr8FH8ZmBXF8dAHMKLEJOI5z8w1'),
                {
                    forceActive: {
                        id: provocation,
                        at: isForced ? new Date('2020-01-01') : Timestamp.now()
                    }
                }
            )
        }
    }

    const allowCommunityResponses = toggle => {
        if (!!provocation) {
            updateDoc(doc(db, 'provocations', provocation), { allowCommunityResponses: toggle })
                .then(() => {
                    setShowAllowCommunity(false)
                })
        }
    }

    const deleteProvocation = () => {
        if (!!provocation) {
            updateDoc(doc(db, 'provocations', provocation), { deleted: Timestamp.now() })
                .then(() => {
                    setShowRemoveResponses(false)
                    onDelete()
                })
        }
    }

    useEffect(() => {
        controls.stop()
        controls.start(emailFocused ? "focus" : "blur")
    }, [emailFocused])

    const sendEmailWithCSV = () => {
        // Call the function with the provocationID and email
        const today = new Date()
        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        sendCSV({ provocation, date: `${today.getDay()} ${monthNames[today.getMonth()]} ${today.getFullYear()}`, prompt: data.prompt, email });
        hideAllCards();
    };

    return <>
        <div
            style={{
                width: '100%', height: 80 * scale,
                background: 'var(--white)',
                position: 'fixed',
                bottom: 0,
                display: 'flex',
                flexFlow: 'row nowrap',
                alignItems: 'center',
                justifyContent: 'center',
                boxShadow: `0 calc(-4px * ${scale}) calc(25px * ${scale}) 0 #0000000A`
            }}
        >
            <div
                style={{
                    display: 'flex', flexFlow: 'row nowrap',
                    alignItems: 'center', justifyContent: 'space-between',
                    paddingLeft: 67 * scale, paddingRight: 67 * scale,
                    width: '100%', height: 30 * scale
                }}
            >
                <Icon style={{ width: 30 * scale, height: 30 * scale }} onClick={() => setShowResetResponses(true)}><ResetResponses color={'var(--paragraph)'} /></Icon>
                <Icon style={{ width: 30 * scale, height: 30 * scale }} onClick={() => toggleForceActive()}><Eye color={`var(${isForced ? '--accent' : '--paragraph'})`} solid={true} /></Icon>
                <Icon style={{ width: 30 * scale, height: 30 * scale }} onClick={() => setShowAllowCommunity(true)}><Profile color={'var(--paragraph)'} solid={false} /></Icon>
                <Icon style={{ width: 30 * scale, height: 30 * scale }} onClick={() => setShowEmailResponses(true)}><Email color={'var(--paragraph)'} /></Icon>
                <Icon style={{ width: 30 * scale, height: 30 * scale }} onClick={() => setShowRemoveResponses(true)}><Delete color={'var(--paragraph)'} /></Icon>
            </div>
        </div>
        <Card
            viewport={viewport} top={539 * scale} unscaledPadding={{ top: 68, right: 54, bottom: 65, left: 54 }}
            visible={showResetResponses}
            dimmer={true}
            dimmerClick={hideAllCards}
        >
            <div
                style={{ width: '100%', height: '100%', display: 'flex', flexFlow: 'column nowrap', alignItems: 'flex-start', justifyContent: 'space-between' }}
            >
                <div>
                    <Text style={{ color: "var(--dark)", margin: 0, fontWeight: 600, fontSize: 25 * scale, lineHeight: '120%', width: 200 * scale }}>
                        Reset all of the responses?
                    </Text>
                    <Text style={{ color: "var(--paragraph)", margin: 0, marginTop: 16 * scale, fontSize: 16 * scale, lineHeight: '150%', width: '100%' }}>
                        Resetting all of the responses for this provocation means all associated data will be deleted
                    </Text>
                </div>
                <div style={{ width: '100%', display: 'flex', flexFlow: 'row nowrap', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Button onClick={() => setShowResetResponses(false)} theme={ButtonThemes.Dark} style={{ width: 130 * scale, height: 70 * scale }}>No</Button>
                    <Button onClick={() => resetResponses()} theme={ButtonThemes.Light} style={{ width: 130 * scale, height: 70 * scale }}>Yes</Button>
                </div>
            </div>
        </Card>
        <Card
            viewport={viewport} top={539 * scale} unscaledPadding={{ top: 68, right: 54, bottom: 65, left: 54 }}
            visible={showAllowCommunity}
            dimmer={true}
            dimmerClick={hideAllCards}
        >
            <div
                style={{ width: '100%', height: '100%', display: 'flex', flexFlow: 'column nowrap', alignItems: 'flex-start', justifyContent: 'space-between' }}
            >
                <div>
                    <Text style={{ color: "var(--dark)", margin: 0, fontWeight: 600, fontSize: 25 * scale, lineHeight: '120%', width: '100%' }}>
                        Allow community responses?
                    </Text>
                    <Text style={{ color: "var(--paragraph)", margin: 0, marginTop: 16 * scale, fontSize: 16 * scale, lineHeight: '150%', width: '100%' }}>
                        Allowing community responses means anybody with the passcode can share their response for this provocation
                    </Text>
                </div>
                <div style={{ width: '100%', display: 'flex', flexFlow: 'row nowrap', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Button onClick={() => allowCommunityResponses(false)} theme={ButtonThemes.Light} style={{ width: 130 * scale, height: 70 * scale }}>No</Button>
                    <Button onClick={() => allowCommunityResponses(true)} theme={ButtonThemes.Dark} style={{ width: 130 * scale, height: 70 * scale }}>Yes</Button>
                </div>
            </div>
        </Card>
        <Card
            viewport={viewport} top={emailFocused ? 103 * scale : 450 /* 450 */ * scale} unscaledPadding={{ top: 68, right: 54, bottom: 65, left: 54 }}
            visible={showEmailResponses}
            dimmer={true}
            dimmerClick={hideAllCards}
        >
            <div
                style={{ width: '100%', height: '100%', display: 'flex', flexFlow: 'column nowrap', alignItems: 'flex-start', justifyContent: 'flex-start' }}
            >
                <div>
                    <Text style={{ color: "var(--dark)", margin: 0, fontWeight: 600, fontSize: 25 * scale, lineHeight: '120%', width: '100%' }}>
                        Send provocation responses
                    </Text>
                    <Text style={{ color: "var(--paragraph)", margin: 0, marginTop: 16 * scale, fontSize: 16 * scale, lineHeight: '150%', width: '100%' }}>
                        The expanded data set relating to this provocation will be sent to the email entered below
                    </Text>
                </div>
                <div style={{ width: '100%' }}>
                    <Input
                        value={email}
                        label={'Email'}
                        placeholder={'name@email.com'}
                        onChange={setEmail}
                        style={{ width: '100%', height: 120 * scale, marginBottom: 60 * scale }}
                        labelStyle={{ color: 'var(--dark)', marginTop: 44 * scale, marginBottom: 11 * scale }}
                        inputStyle={{ width: `calc(100% - ${23 * scale}px)` }}
                        type="single"
                        maxlength={120}
                        onFocus={() => setEmailFocused(true)}
                        onBlur={() => setEmailFocused(false)}
                    />
                </div>
                <motion.div
                    variants={{
                        focus: {
                            flexGrow: 0
                        },
                        blur: {
                            flexGrow: 1
                        }
                    }}
                    animate={controls}
                />
                <div style={{ width: '100%', display: 'flex', flexFlow: 'row nowrap', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Button onMouseDown={(e) => e.preventDefault()} onClick={hideAllCards} enabled={!sendCSVLoading} theme={ButtonThemes.Light} style={{ width: 130 * scale, height: 70 * scale }}>No</Button>
                    <Button onMouseDown={(e) => e.preventDefault()} onClick={sendEmailWithCSV} loading={sendCSVLoading} loadingMessage='Sending...' theme={ButtonThemes.Dark} style={{ width: 130 * scale, height: 70 * scale }}>Yes</Button>
                </div>
            </div>
        </Card>
        <Card
            viewport={viewport} top={539 * scale} unscaledPadding={{ top: 68, right: 54, bottom: 65, left: 54 }}
            visible={showRemoveResponses}
            dimmer={true}
            dimmerClick={hideAllCards}
        >
            <div
                style={{ width: '100%', height: '100%', display: 'flex', flexFlow: 'column nowrap', alignItems: 'flex-start', justifyContent: 'space-between' }}
            >
                <div>
                    <Text style={{ color: "var(--dark)", margin: 0, fontWeight: 600, fontSize: 25 * scale, lineHeight: '120%', width: '100%' }}>
                        Remove this provocation & all responses?
                    </Text>
                    <Text style={{ color: "var(--paragraph)", margin: 0, marginTop: 16 * scale, fontSize: 16 * scale, lineHeight: '150%', width: '100%' }}>
                        Removing this provocation means all associated data will be deleted
                    </Text>
                </div>
                <div style={{ width: '100%', display: 'flex', flexFlow: 'row nowrap', alignItems: 'center', justifyContent: 'space-between' }}>
                    <Button onClick={() => setShowRemoveResponses(false)} theme={ButtonThemes.Dark} style={{ width: 130 * scale, height: 70 * scale }}>No</Button>
                    <Button onClick={() => deleteProvocation()} theme={ButtonThemes.Light} style={{ width: 130 * scale, height: 70 * scale }}>Yes</Button>
                </div>
            </div>
        </Card>
    </>
}

export default Bottombar