import Text from "../components/text/Text"
import Button, { ButtonThemes } from "../components/Button"
import { useContext, useEffect, useState } from "react"
import ResponseCountList from "../components/ResponseCountList"
import { collection, doc, onSnapshot } from "firebase/firestore";
import { db, functions } from "../utils/firebase";
import { ScaleContext } from "../utils/Scale";
import { useHttpsCallable } from "react-firebase-hooks/functions";
import Icon, { Community, Profile } from "../components/Icon"

const initialState = {
    student: [],
    parent: [],
    staff: [],
    visitor: [],
    all: []
}

const fillResponseCount = 50

const ViewProvocation = ({ viewport, data }) => {
    const [category, setCategory] = useState('student')
    const [responses, setResponses] = useState(JSON.parse(JSON.stringify(initialState)))
    const [code, setCode] = useState('')
    const [ready, setReady] = useState(false)
    const { scale } = useContext(ScaleContext)
    const [fillResponses, fillResponsesLoading] = useHttpsCallable(functions, 'fillResponses')

    const categoryStyle = {
        width: 70 * scale, height: 30 * scale,
        borderRadius: 7 * scale,
        fontSize: 10.24 * scale
    }

    const handleFillResponses = () => {
        const responseData = {
            id: data.id,
            responseCount: fillResponseCount,
            category: category
        };

        // Call the fillResponses function
        fillResponses(responseData)
            .then(() => {
                console.log('Batch write successful');
                // Handle success
            })
            .catch((error) => {
                console.error('Error calling fillResponses:', error);
                // Handle error
            });
    };


    useEffect(() => {
        !!data &&
            onSnapshot(collection(db, `provocations/${data.id}/responses`), snapshot => {
                const next = JSON.parse(JSON.stringify(initialState))
                snapshot.forEach(doc => {
                    const docData = doc.data()
                    if (docData?.role) {
                        let response = {
                            id: doc.id,
                            provocationId: data.id,
                            ...docData
                        }

                        response.created = response?.created?.toDate() || new Date()

                        const push = () => {
                            next.all.push(response)
                            if (!next.hasOwnProperty(response.role)) next[response.role] = []
                            next[response.role].push(response)
                        }

                        if (!next.all.find(existing => existing.id === response.id)) push()
                    }
                })

                setResponses({ ...next })
                setReady(true)
            })
    }, [JSON.stringify(data)])

    useEffect(() => {
        if (ready) {
            onSnapshot(doc(db, `provocations_private`, data.id), snapshot => {
                let snapData = snapshot.data()
                setCode(snapData.code)
            })
        }
    }, [ready])

    return <>
        <div
            style={{
                width: '100%',
                marginTop: 20 * scale
            }}
        >
            <div
                style={{
                    width: '100%',
                    height: 300 * scale,
                    borderRadius: 20 * scale,
                    margin: 0,
                    display: 'flex',
                    flexFlow: 'column nowrap',
                    justifyContent: 'center'
                }}
            >
                <div style={{ flexGrow: 1 }} />
                <div>
                    <Text style={{ color: "var(--dark)", margin: 0, fontWeight: 600, fontSize: 25 * scale, lineHeight: '120%', width: '100%' }}>{data?.dev &&
                        <span style={{ color: 'red' }}>[dev]</span>}{data?.prompt}</Text>
                    <Text style={{ color: "var(--paragraph)", margin: 0, marginTop: 10 * scale, fontSize: 12.8 * scale, lineHeight: `calc(18px * ${scale})`, width: '100%' }}>0 - {data?.type.min}</Text>
                    <Text style={{ color: "var(--paragraph)", margin: 0, marginTop: 0, fontSize: 12.8 * scale, lineHeight: `calc(18px * ${scale})`, width: '100%' }}>10 - {data?.type.max}</Text>
                </div>
                <div style={{ flexGrow: 2 }} />
                <div>
                    <div
                        style={{
                            display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-between', alignItems: 'center'
                        }}
                    >
                        <Text style={{ color: "var(--dark)", margin: 0, fontWeight: 600, fontSize: 16 * scale, width: 120 * scale }}>Categories</Text>
                        <div style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-end', width: 120 * scale }}>
                            {!!!data?.allowCommunityResponses &&
                                <Icon style={{ width: 22 * scale, height: 22 * scale, margin: 0, padding: 0 }}>
                                    <Profile color={'var(--paragraph)'} solid={true} />
                                </Icon>
                            }
                            {!!data?.allowCommunityResponses &&
                                <Icon style={{ width: 24 * scale, height: 24 * scale, margin: 0, padding: 0 }}>
                                    <Community color={'var(--paragraph)'} solid={true} />
                                </Icon>
                            }
                            <Text style={{ color: "var(--paragraph50)", margin: 0, fontSize: 15 * scale, width: 90 * scale, textAlign: 'right' }}>Code: {code}</Text>
                        </div>

                    </div>
                    <div
                        style={{
                            display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', alignItems: 'center', marginTop: 13 * scale, gap: 15 * scale
                        }}
                    >
                        <Button onClick={() => setCategory('student')} theme={category === 'student' ? ButtonThemes.Dark : ButtonThemes.Light} style={categoryStyle}>Student</Button>
                        <Button onClick={() => setCategory('parent')} theme={category === 'parent' ? ButtonThemes.Dark : ButtonThemes.Light} style={categoryStyle}>Parent</Button>
                        <Button onClick={() => setCategory('staff')} theme={category === 'staff' ? ButtonThemes.Dark : ButtonThemes.Light} style={categoryStyle}>Staff</Button>
                        <Button onClick={() => setCategory('visitor')} theme={category === 'visitor' ? ButtonThemes.Dark : ButtonThemes.Light} style={categoryStyle}>Visitor</Button>
                        {ready && !!data.dev && <Button onClick={() => handleFillResponses()} theme={ButtonThemes.Dark}
                            style={{ ...categoryStyle, width: 100 * scale, backgroundColor: 'red' }}>+{fillResponseCount} Responses</Button>}
                    </div>
                </div>
            </div>
        </div>
        <ResponseCountList responses={responses.student.filter(response => data.resetResponses === null || response.created > data.resetResponses).map(response => response.value)}
            visible={ready && category === 'student'} viewport={viewport} />
        <ResponseCountList responses={responses.parent.filter(response => data.resetResponses === null || response.created > data.resetResponses).map(response => response.value)}
            visible={ready && category === 'parent'} viewport={viewport} />
        <ResponseCountList responses={responses.staff.filter(response => data.resetResponses === null || response.created > data.resetResponses).map(response => response.value)}
            visible={ready && category === 'staff'} viewport={viewport} />
        <ResponseCountList responses={responses.visitor.filter(response => data.resetResponses === null || response.created > data.resetResponses).map(response => response.value)}
            visible={ready && category === 'visitor'} viewport={viewport} />
    </>
}

export default ViewProvocation