import { useContext, useEffect, useRef, useState } from "react"
import { motion, useAnimationControls } from 'framer-motion'
import { ScaleContext } from "../utils/Scale"

const ButtonThemes = {
    Light: {
        backgroundColor: "var(--white)",
        color: "var(--dark)"
    },
    Dark: {
        backgroundColor: "var(--accent)",
        color: "var(--white)"
    }
}

const Button = ({theme, style, onClick, children, loading, loadingMessage='Loading', keypad = false, enabled = true, onMouseDown = () => {}}) => {
    const [styles, setStyles] = useState({backgroundColor: 'var(--accent)'})
    const {scale} = useContext(ScaleContext)

    const controls = useAnimationControls()

    const [baseStyle, setBaseStyle] = useState({
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        width: '100%',
        fontWeight: 600
    })

    const KeypadStyle = {
        borderRadius: 10 * scale * 1.1,
        fontWeight: 400,
        fontSize: 25 * scale
    }

    const animation = {
        whileTap: {
            scale: 0.95,
            boxShadow: '0 2px 7.5px 0 var(--dark15)'
        }
    }

    useEffect(() => {
        setBaseStyle({
            ...baseStyle,
            height: 80 * scale,
            borderRadius: 20 * scale,
            fontSize: 20 * scale,
            boxShadow: '0 8px 25px 0 var(--dark15)',
        })
    }, [scale])

    useEffect(() => {
        setStyles({
            ...baseStyle,
            ...(keypad ? KeypadStyle : {}),
            ...theme,
            ...style
        })
    }, [theme, style, keypad, baseStyle])

    useEffect(() => {
        controls.stop()
        controls.start(enabled ? "enabled" : "disabled")
    }, [enabled, styles])

    return <>
        <motion.div
            style={styles}
            onMouseDown={onMouseDown}
            onClick={enabled ? onClick : () => {}}
            variants={{
                enabled: {
                    backgroundColor: styles.backgroundColor
                },
                disabled: {
                    backgroundColor: 'var(--paragraph)'
                }
            }}
            animate={controls}
            whileTap={enabled ? {
                ...animation.whileTap,
                ...(keypad ? (theme === ButtonThemes.Light ? ButtonThemes.Dark : ButtonThemes.Light) : null)
            } : {}}
        >
            {
                loading ? loadingMessage : children
            }
        </motion.div>
    </>
}

export default Button
export { ButtonThemes }