import Text from "./text/Text"
import Button, { ButtonThemes } from "./Button"
import Icon, { Backspace } from "./Icon"
import { motion, useAnimationControls } from 'framer-motion'
import { useContext, useEffect, useMemo, useState } from "react";
import { ScaleContext } from "../utils/Scale";
import HomeBackground from "../pages/HomeBackground";

const PinCode = ({code = '', setCode}) => {
    const [previousCodeLength, setPreviousCodeLength] = useState(0)
    const controls = useAnimationControls()
    const {scale} = useContext(ScaleContext)

    const padScale = useMemo(() => scale * 1.1, [scale])

    const handleKeyDown = (input) => {
        if (input === 'backspace') {
            if (code.length > 0)
                setCode(code.slice(0, -1))
        } else if (code.length < 4)
            setCode(code + input)
    }

    useEffect(() => {
        if (code.length === 4) controls.start({y: -20 * scale, transitionEnd: {y: 0}})
        else controls.start({y: 0, transitionEnd: {y: 0}})
        setPreviousCodeLength(code.length)
    }, [code])

    return <>
        <div
            style={{
                marginTop: 72 * scale,
                display: 'flex',
                flexFlow: 'column nowrap',
                alignItems: 'center'
            }}
        >
            <Text style={{color: "var(--white)", margin: 0, fontWeight: 600, fontSize: 30 * scale, lineHeight: '120%', width: '100%', textAlign: 'center'}}>Enter code</Text>
        </div>
        <div
            style={{
                display: 'flex',
                flexFlow: 'row nowrap',
                width: 147 * scale,
                justifyContent: 'space-between',
                marginTop: 50 * scale, marginBottom: 0
            }}
        >
            {
                [...Array(4).keys()].map(i => {
                    return (
                        <motion.div
                            key={i}
                            style={{
                                height: 18 * scale, width: 18 * scale,
                                borderRadius: 9 * scale,
                                background: code.length > i ? 'var(--white)' : 'var(--accent)'
                            }}
                            initial={{y: 0}}
                            animate={controls}
                            exit={{y: 0, transition: {duration: 0, delay: 0}}}
                            transition={{
                                type: "tween",
                                duration: 0.5,
                                delay: 0.2 * i,
                                ease: "easeInOut",
                                repeat: code.length === 4 ? Infinity : 0,
                                repeatType: "reverse"
                            }}
                        >

                        </motion.div>
                    )
                })
            }
        </div>
        {
            [...Array(3).keys()].map(i => {
                return (
                    <div
                        key={i}
                        style={{
                            width: 270 * padScale, marginTop: i === 0 ? 70 * padScale : 30 * padScale,
                            display: 'flex', flexFlow: 'row nowrap',
                            gap: 30 * padScale, padding: '0 calc((100% - (270px * ' + padScale + ')) / 2)'
                        }}
                    >
                        {
                            [...Array(3).keys()].map(j => {
                                j++
                                const value = i * 3 + j
                                return (
                                    <Button key={value} keypad={true} theme={ButtonThemes.Dark} style={{height: 70 * padScale, width: 70 * padScale}} onClick={() => handleKeyDown(value)}>{value}</Button>
                                )
                            })
                        }
                    </div>
                )
            })
        }
        <div
            key={3}
            style={{
                width: 270 * padScale, marginTop: 30 * padScale,
                display: 'flex', flexFlow: 'row nowrap',
                gap: 30 * padScale, padding: '0 calc((100% - (270px * ' + padScale + ')) / 2)'
            }}
        >
            <Button key={'blank'} keypad={true} style={{height: 70 * padScale, width: 70 * padScale, opacity: 0}}></Button>
            <Button key={0} keypad={true} theme={ButtonThemes.Dark} style={{height: 70 * padScale, width: 70 * padScale}} onClick={() => handleKeyDown(0)}>{0}</Button>
            <Button key={'backspace'} keypad={true} theme={ButtonThemes.Dark} style={{height: 70 * padScale, width: 70 * padScale}} onClick={() => handleKeyDown('backspace')}>
                <Icon>
                    <Backspace color={'var(--white)'}/>
                </Icon>
            </Button>
        </div>
    </>
}

export default PinCode