import Text from "./text/Text"
import flower00 from "../images/Flower 00.png"
import flower01 from "../images/Flower 01.png"
import flower02 from "../images/Flower 02.png"
import flower03 from "../images/Flower 03.png"
import flower04 from "../images/Flower 04.png"
import flower05 from "../images/Flower 05.png"
import flower06 from "../images/Flower 06.png"
import flower07 from "../images/Flower 07.png"
import flower08 from "../images/Flower 08.png"
import flower09 from "../images/Flower 09.png"
import flower10 from "../images/Flower 10.png"
import RatingHandle from "./RatingHandle"
import { useContext, useEffect, useMemo, useState } from "react"
import Button, { ButtonThemes } from "./Button"
import { ScaleContext } from "../utils/Scale"
import { motion } from "framer-motion"

const flowers = [flower00, flower01, flower02, flower03, flower04, flower05, flower06, flower07, flower08, flower09, flower10]

const positions = [...Array(11).keys()].map(i => 360 / 12 * (i + 1) + i - 10)

const ShareResponse = ({addToResponse, provocation, submit, loading, viewport}) => {
    const [value, setValue] = useState(0)
    const [exactValue, setExactValue] = useState(0)
    const {scale} = useContext(ScaleContext)
    const [imageLoaded, setImageLoaded] = useState({})

    const handleLoadImage = (image) => {
        setTimeout(() => setImageLoaded(prev => ({...prev, [image]: true})), 1000)
    }

    const allLoaded = useMemo(() =>
            imageLoaded['img0'] && imageLoaded['img1'] && imageLoaded['img2'] &&
            imageLoaded['img3'] && imageLoaded['img4'] && imageLoaded['img5'] &&
            imageLoaded['img6'] && imageLoaded['img7'] && imageLoaded['img8'] &&
            imageLoaded['img9'] && imageLoaded['img10']
        , [imageLoaded])

    useEffect(() => {
        addToResponse({value: value})
    }, [value])

    return <>
        <div
            style={{
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'flex-end',
                marginTop: 50 * scale,
                width: '100%'
            }}
        >
            <Text style={{color: "var(--dark)", margin: 0, fontWeight: 600, fontSize: 30 * scale, lineHeight: '120%', width: '100%'}}>{provocation.prompt}</Text>
            <Text style={{color: "var(--paragraph)", margin: 0, marginTop: 10 * scale, fontSize: 18 * scale, lineHeight: '150%'}}>0 - {provocation.type.min}</Text>
            <Text style={{color: "var(--paragraph)", margin: 0, marginTop: 0, fontSize: 18 * scale, lineHeight: '150%'}}>10 - {provocation.type.max}</Text>
        </div>
        <div
            style={{
                position: 'relative',
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
                marginBottom: 50 * scale,
                marginTop: 50 * scale,
                maxHeight: 320 * scale,
                flexGrow: 1
            }}
        >
            {
                [...Array(11).keys()].map(i => {
                    return (
                        <img
                            key={i}
                            src={flowers[i]}
                            onLoad={() => handleLoadImage('img' + i)}
                            style={{
                                position: 'absolute',
                                top: 0,
                                height: '100%', width: 'auto',
                                opacity: Math.min(Math.max(exactValue * 10 - i + 1, 0), 1)
                            }}
                        />
                    )
                })
            }
        </div>
        <div
            style={{
                position: 'relative',
                display: 'flex',
                flexFlow: 'column',
                justifyContent: 'flex-end',
                alignItems: 'center',
                width: 400 * scale, minHeight: 110 * scale,
                marginTop: 25 * scale
            }}
        >
            <div
                style={{
                    position: 'absolute', top: 0, left: 0,
                    borderRadius: 20,
                    width: 400 * scale, height: 80 * scale,
                    background: 'var(--white)',
                    boxShadow: '0 8px 25px 0 var(--dark15)',
                    display: 'flex', alignItems: 'center', justifyContent: 'center'
                }}
            >
                <div
                    style={{
                        position: 'relative',
                        background: 'linear-gradient(90deg, rgba(255,172,172,1) 0%, rgba(255,201,163,1) 11%, rgba(255,239,157,1) 22%, rgba(190,254,184,1) 33%, rgba(190,255,232,1) 44%, rgba(204,237,255,1) 55%, rgba(189,204,255,1) 67%, rgba(195,174,255,1) 78%, rgba(239,195,254,1) 89%, rgba(255,209,244,1) 100%)',
                        width: 350 * scale, height: 40 * scale,
                        borderRadius: 15 * scale
                    }}
                >
                    <RatingHandle positions={positions} value={value} setValue={setValue} setExactValue={setExactValue}/>
                    <div
                        style={{
                            position: 'absolute', top: 0, left: 0,
                            width: 350 * scale, height: 40 * scale,
                            display: 'flex', flexFlow: 'row nowrap', alignItems: 'center', justifyContent: 'space-around',
                            pointerEvents: "none"
                        }}
                    >
                        {
                            positions.map((left, i) => {
                                let dotSize = 10
                                if (exactValue !== null) {
                                    let diff = Math.abs(i / 10 - exactValue)
                                    if (diff < 0.05) {
                                        diff *= 20
                                        diff = 1 - diff
                                        dotSize += diff * 6
                                    }
                                }
                                return <div
                                    key={i}
                                    style={{
                                        background: 'var(--white)', borderRadius: dotSize * scale / 2,
                                        width: dotSize * scale, height: dotSize * scale,
                                        position: 'absolute',
                                        top: 20 * scale - dotSize * scale / 2, left: left * scale - dotSize * scale / 2,
                                        pointerEvents: "none"
                                    }}
                                />
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
        <div
            style={{
                height: 100 * scale,
                width: '100%'
            }}
        >
            <Button onClick={submit} theme={ButtonThemes.Dark} loading={loading} loadingMessage={"Planting..."}>Share response</Button>
        </div>
    </>
}

export default ShareResponse