import { initializeApp } from "firebase/app"
import { getFirestore, doc, onSnapshot } from 'firebase/firestore'
import { getAuth } from "firebase/auth"
import { createContext, useContext, useEffect, useState } from 'react'
import { getFunctions } from 'firebase/functions'

const firebaseConfig = {
    apiKey: "AIzaSyCDeeUN3Fm7hAH7vAEDp-VkOyV3aLPvETA",
    authDomain: "sps-provocation-garden.firebaseapp.com",
    projectId: "sps-provocation-garden",
    storageBucket: "sps-provocation-garden.appspot.com",
    messagingSenderId: "85267405907",
    appId: "1:85267405907:web:8ae34c0ca2564537b23aa6"
}

// FIREBASE

const app = initializeApp(firebaseConfig)

// FIRESTORE

const db = getFirestore(app)

// FUNCTIONS

const functions = getFunctions(app)

// AUTH

const AuthContext = createContext(null)

const Auth = ({children}) => {
    const auth = getAuth()
    const [username, setUsername] = useState(null)

    useEffect(() => {
        // turn off realtime subscription
        let unsubscribe

        if (auth?.currentUser?.uid) {
            unsubscribe = onSnapshot(doc(db, 'users', auth.currentUser.uid),
                (doc) => {
                    setUsername(doc.data()?.username)
                })
        } else {
            setUsername('Not logged in')
        }

        return unsubscribe
    }, [auth])

    return (
        <AuthContext.Provider value={{user: auth.currentUser, username: username}}>
            {children}
        </AuthContext.Provider>
    )
}

const useAuthData = () => {
    const authData = useContext(AuthContext)
    return authData
}

export default app
export { db, functions, useAuthData, Auth }