import { createContext, useEffect, useState } from "react"

// Context

const CookieContext = createContext(null)

const Cookies = ({children}) => {
    const [cookies, setCookies] = useState({})

    useEffect(() => {
        checkCookies()
    }, [])

    const checkCookies = () => {
        let newCookies = {}
        // read out cookies
        document.cookie.replace(/([^=;]+)=([^;]*)/gi, (m,key,value) => {
            newCookies[key.replace(/(^\s+|\s+$)/g,'')] = value.replace(/(^\s+|\s+$)/g,'')
        })
        setCookies({...cookies, ...newCookies})
    }

    const handleGetCookie = provocation => cookies[provocation]

    const handleAddCookie = (provocation, response) => {
        document.cookie = `${provocation}=${response}; expires=Fri, 31 Dec 9999 23:59:59 GMT`
        checkCookies()
    }

    return (
        <CookieContext.Provider value={{ Get: handleGetCookie, Add: handleAddCookie}} getCookie={handleGetCookie} addCookie={handleAddCookie}>
            {children}
        </CookieContext.Provider>
    )
}

export default Cookies

export { CookieContext }