import Text from "../components/text/Text"
import Icon, { Close } from "../components/Icon"
import Button, { ButtonThemes } from "../components/Button"
import Input from "../components/Input"
import { useContext, useState } from "react"
import Dropdown from "../components/Dropdown"
import Checkbox from "../components/Checkbox"
import { addDoc, collection } from "firebase/firestore"
import { db, functions } from "../utils/firebase"
import { ScaleContext } from "../utils/Scale";
import { useHttpsCallable } from "react-firebase-hooks/functions"
import { AnimatePresence } from "framer-motion"

const AddProvocation = ({ close, goToProvocation, isDev }) => {
    const [title, setTitle] = useState('')
    const [type, setType] = useState(null)
    const [allowCommunity, setAllowCommunity] = useState(true)
    const [dev, setDev] = useState(false)
    const { scale } = useContext(ScaleContext)
    const [addProvocation, addProvocationLoading] = useHttpsCallable(functions, 'addProvocation')

    const onTitleChange = (value) => setTitle(value.length > 120 ? value.substring(0, 120) : value)

    const handleSubmit = async () => {
        let provocation = {}
        provocation.prompt = title
        provocation.type = type
        provocation.allowCommunityResponses = allowCommunity
        provocation.dev = dev
        addProvocation({ provocation: { ...provocation } })
            .then((result) => {
                let id = result?.data?.id
                !!id && goToProvocation(id)
            })
            .catch((error) => {
                // Handle any errors that occurred during the function call
                console.error(error)
            })
    }

    return <>
        <div style={{ height: '100%', width: '100%', position: 'relative' }}>
            <div
                style={{ display: 'flex', flexFlow: 'row nowrap', justifyContent: 'space-between' }}
            >
                <Text style={{ color: "var(--dark)", margin: 0, fontWeight: 600, fontSize: 25 * scale, lineHeight: '120%', width: 160 * scale }}>Add new provocation</Text>
                <Icon onClick={() => close()}>
                    <Close color={'var(--dark)'} />
                </Icon>
            </div>
            <Input
                value={title}
                label={'Text'}
                placeholder={'Maximum 120 characters'}
                onChange={onTitleChange}
                style={{ width: '100%', height: 260 * scale }}
                labelStyle={{ color: 'var(--dark)', marginTop: 44 * scale, marginBottom: 11 * scale }}
                inputStyle={{ width: `calc(100% - ${23 * scale}px)` }}
                type={'multi'}
                maxlength={120}
            />
            <Text style={{ color: "var(--dark)", marginTop: 57 * scale, marginBottom: 11 * scale, fontWeight: 600, fontSize: 16 * scale, lineHeight: '150%', width: 160 * scale }}>Settings</Text>
            <Dropdown label={'Select response type'} value={type} onChange={value => setType(value)} />
            <Checkbox
                value={allowCommunity}
                onChange={setAllowCommunity}
                backgroundColor={'var(--accent)'}
                tickColor={'var(--white)'}
            >Allow community responses</Checkbox>
            {
                isDev &&
                <Checkbox
                    value={dev}
                    onChange={setDev}
                    backgroundColor={'red'}
                    tickColor={'var(--white)'}
                    dev={true}
                >Development test</Checkbox>
            }
            <AnimatePresence>
                <Button
                    theme={ButtonThemes.Dark}
                    style={{
                        position: 'absolute',
                        bottom: 0
                    }}
                    enabled={title.length > 0 && type !== null}
                    onClick={handleSubmit}
                >
                    Post provocation
                </Button>
            </AnimatePresence>

        </div>
    </>
}

export default AddProvocation