import Icon, { Arrow, Community, Profile } from "./Icon"
import { motion } from "framer-motion"
import { useContext } from "react";
import { ScaleContext } from "../utils/Scale";

const ProvocationSummary = ({community = false, prompt = "", responses = 0, dev = false, onClick}) => {
    const {scale} = useContext(ScaleContext)

    const animation = {
        whileTap: {
            scale: 0.95,
            boxShadow: `0 calc(2px * ${scale}) calc(7.5px * ${scale}) 0 rgba(0, 0, 0, 0.07)`
        }
    }

    return <>
        <motion.div
            onClick={() => onClick()}
            whileTap={animation.whileTap}
            style={{
                width: `calc(50% - 35px * ${scale})`, height: `calc(70px * ${scale} - 34px * ${scale})`,
                paddingLeft: 20 * scale,
                paddingRight: 20 * scale,
                paddingTop: 17 * scale,
                paddingBottom: 17 * scale,
                borderRadius: 20 * scale,
                boxShadow: `0 calc(8px * ${scale}) calc(25px * ${scale}) 0 rgba(0, 0, 0, 0.07)`,
                display: 'flex',
                flexFlow: 'row nowrap',
                justifyContent: 'space-between',
                alignItems: 'center',
                gap: 20 * scale,
                marginLeft: 20 * scale,
                marginRight: 20 * scale
            }}
        >
            <div
                style={{
                    width: 36 * scale, height: 36 * scale,
                    borderRadius: 10 * scale,
                    background: 'var(--light2)',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Icon
                    style={{width: 24 * scale, height: 24 * scale}}
                >
                    {community && <Community color={'var(--dark)'}/>}
                    {!community && <Profile color={'var(--dark)'}/>}
                </Icon>
            </div>
            <div
                style={{
                    display: 'flex',
                    flexFlow: 'column nowrap',
                    justifyContent: 'space-between',
                    alignItems: 'flex-start',
                    width: `calc(100% - 112px * ${scale})`
                }}
            >
            <span style={{
                color: "var(--dark)",
                fontWeight: 600, fontSize: 17 * scale,
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                width: '100%'
            }}>{dev && <span style={{color: 'red'}}>[dev]</span>}{prompt}</span>
                <span style={{
                    color: "var(--dark)",
                    fontSize: 11 * scale,
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    height: 15 * scale
                }}>{responses} responses</span>
            </div>
            <div
                style={{
                    width: 36 * scale, height: 36 * scale,
                    borderRadius: 10 * scale,
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center'
                }}
            >
                <Icon
                    style={{width: 24 * scale, height: 24 * scale}}
                >
                    <Arrow direction={'right'} color={'var(--dark)'}/>
                </Icon>
            </div>
        </motion.div>
    </>
}

export default ProvocationSummary