import Text from "./text/Text"
import Button, { ButtonThemes } from "./Button"
import Character from "../images/Character.png"
import Icon, { Add } from "./Icon"
import { motion } from "framer-motion";
import { useContext } from "react";
import { ScaleContext } from "../utils/Scale";
import Squiggle from "../images/Squiggle2Accent50.png";

const AddProvocation = ({onClick}) => {
    const {scale} = useContext(ScaleContext)
    return <>
        <div
            style={{
                background: 'var(--light2)',
                width: '100%',
                height: 260 * scale,
                borderRadius: 20 * scale,
                margin: 0,
                display: 'flex',
                flexFlow: 'row nowrap',
                overflow: "hidden"
            }}
        >
            <div style={{width: '50%', height: '100%', position: 'relative'}}>
                <Text style={{color: "var(--dark)", paddingTop: 10 * scale, paddingLeft: 20 * scale, marginBottom: 7 * scale}}>It's simple!</Text>
                <Text style={{color: "var(--dark)", paddingLeft: 20 * scale, marginTop: 7 * scale, fontWeight: 600, fontSize: 25 * scale, lineHeight: '120%', width: 176 * scale}}>Add or view a provocation</Text>
                <Button
                    theme={ButtonThemes.Dark}
                    style={{
                        position: 'absolute',
                        width: 90 * scale, height: 50 * scale,
                        bottom: 20 * scale, left: 20 * scale,
                        fontSize: 16 * scale
                    }}
                    onClick={onClick}
                >
                    Add
                    <Icon style={{width: 24 * scale, height: 24 * scale, marginLeft: 2 * scale, marginTop: -2 * scale}}><Add color={'var(--white)'}/></Icon>
                </Button>
            </div>
            <div style={{width: '50%', height: '100%', position: 'relative'}}>
                <img src={Squiggle} style={{
                    mixBlendMode: 'screen',
                    position: 'absolute',
                    top: -35 * scale, right: -10 * scale,
                    width: 260 * scale, height: 320 * scale
                }}/>
                <img
                    src={Character}
                    style={{
                        position: 'absolute',
                        bottom: 20 * scale, right: -10 * scale,
                        width: 229 * scale, height: 198 * scale
                    }}
                />
            </div>
        </div>
    </>
}

export default AddProvocation