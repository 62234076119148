import Button, { ButtonThemes } from "../components/Button"
import { AnimatePresence, motion } from "framer-motion";
import { useContext, useEffect, useState } from "react";
import { ScaleContext } from "../utils/Scale";

const Home = ({onAdmin, onRespond, signedIn, signOut, viewport}) => {
    const {scale} = useContext(ScaleContext)
    const [padding, setPadding] = useState({width: 50 * scale, height: 70 * scale})
    const [scrollInterval, setScrollInterval] = useState(null)

    useEffect(() => {
        if (scrollInterval === null) {
            setScrollInterval(setInterval(() => window.scroll({top: 0, behavior: "instant"}), 100))
        }
    }, [scrollInterval])

    useEffect(() => {
        setPadding({
            width: 50 * scale,
            height: 70 * scale
        })
    }, [scale])

    return <div
        style={{
            width: `${viewport.width - padding.width * 2}px`,
            height: `${viewport.height - padding.height * 2}px`,
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'center',
            justifyContent: 'flex-end',
            padding: `${padding.height}px ${padding.width}px`,
            gap: 20 * scale
        }}
    >
        <AnimatePresence>
            <motion.div
                key={0}
                style={{
                    width: '100%'
                }}
                initial={{
                    y: 400 * scale
                }}
                animate={{
                    y: '0px'
                }}
                exit={{
                    y: 400 * scale,
                    transition: {
                        delay: 0,
                        duration: 1,
                        type: "tween",
                        ease: "easeInOut"
                    }
                }}
                transition={{
                    delay: 2,
                    duration: 1,
                    type: "tween",
                    ease: "easeOut"
                }}
            >
                <Button onClick={onRespond} theme={ButtonThemes.Light}>Share response</Button>
            </motion.div>
            <motion.div
                key={1}
                style={{
                    width: '100%'
                }}
                initial={{
                    y: 400 * scale
                }}
                animate={{
                    y: '0px'
                }}
                exit={{
                    y: 400 * scale,
                    transition: {
                        delay: 0,
                        duration: 1,
                        type: "tween",
                        ease: "easeInOut"
                    }
                }}
                transition={{
                    delay: 2.25,
                    duration: 1,
                    type: "tween",
                    ease: "easeOut"
                }}
            >
                <Button onClick={onAdmin} theme={ButtonThemes.Dark}>{signedIn ? 'Manage' : 'Sign in'}</Button>
            </motion.div>
            {signedIn &&
                <motion.div
                    key={2}
                    style={{
                        width: '100%',
                        textAlign: 'center',
                        bottomMargin: -25 * scale
                    }}
                    initial={{
                        opacity: 0
                    }}
                    animate={{
                        opacity: 1
                    }}
                    exit={{
                        opacity: 0,
                        transition: {
                            delay: 0,
                            duration: 1,
                            type: "tween",
                            ease: "easeInOut"
                        }
                    }}
                    transition={{
                        delay: 2.5,
                        duration: 1,
                        type: "tween",
                        ease: "easeOut"
                    }}
                >
                    <a onClick={signOut}>Sign out</a>
                </motion.div>
            }
        </AnimatePresence>
    </div>
}

export default Home