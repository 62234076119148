import Icon, { Arrow, ResponseType } from "./Icon"
import { useContext, useEffect, useRef, useState } from "react"
import { ScaleContext } from "../utils/Scale";

const options = [
    'Not/Completely Satisfied',
    'Not/Completely Worthwhile',
    'Not/Completely True of Me',
    'Poor/Excellent',
    'Strongly Disagree/Agree',
    'Extremely Unhappy/Happy'
]

const type = {
    'Not/Completely Satisfied': {
        display: 'Not/Completely Satisfied',
        max: 'Completely Satisfied',
        min: 'Not Satisfied'
    },
    'Not/Completely Worthwhile': {
        display: 'Not/Completely Worthwhile',
        max: 'Completely Worthwhile',
        min: 'Not Worthwhile'
    },
    'Not/Completely True of Me': {
        display: 'Not/Completely True of Me',
        max: 'Completely True of Me',
        min: 'Not True of Me'
    },
    'Poor/Excellent': {
        display: 'Poor/Excellent',
        max: 'Excellent',
        min: 'Poor'
    },
    'Strongly Disagree/Agree': {
        display: 'Strongly Disagree/Agree',
        max: 'Strongly Agree',
        min: 'Strongly Disagree'
    },
    'Extremely Unhappy/Happy': {
        display: 'Extremely Unhappy/Happy',
        max: 'Extremely Happy',
        min: 'Extremely Unhappy'
    }
}

const Dropdown = ({label, onChange}) => {
    const wrapperRef = useRef(null)
    const [showOptions, setShowOptions] = useState(false)
    const [value, setValue] = useState(label)
    const {scale} = useContext(ScaleContext)

    useEffect(() => {
        const handleClick = event => wrapperRef.current && !wrapperRef.current.contains(event.target) && setShowOptions(false)

        document.addEventListener('mousedown', handleClick)
        return () => document.removeEventListener('mousedown', handleClick)
    }, [wrapperRef])

    return <>
        <div
            ref={wrapperRef}
            style={{
                position: 'relative',
                color: 'var(--paragraph)',
                width: '100%',
                height: 54 * scale,
                display: 'flex',
                flexFlow: 'row nowrap',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderBottom: '1px solid #E8E8E8'
            }}
            onClick={() => setShowOptions(true)}
        >
            <div
                style={{
                    width: '100%',
                    display: 'flex', flexFlow: 'row nowrap',
                    justifyContent: 'space-between', alignItems: 'center'
                }}
            >
                <div style={{display: 'flex', flexFlow: 'row nowrap', alignItems: 'center', fontSize: 16 * scale}}>
                    <Icon style={{width: 26 * scale, height: 26 * scale, marginRight: 10 * scale}}>
                        <ResponseType color={'var(--paragraph)'}/>
                    </Icon>
                    {value}
                </div>
                <Icon style={{width: 26 * scale, height: 26 * scale, marginRight: 17 * scale}}>
                    <Arrow direction={'down'} color={'var(--paragraph)'}/>
                </Icon>
            </div>
            {
                showOptions && <>
                    <div
                        style={{
                            position: 'absolute',
                            bottom: 0, left: 0,
                            background: 'var(--white)',
                            width: '100%',
                            boxShadow: `0 0 ${25 * scale}px 0 #0000000A`,
                            fontSize: 16 * scale
                        }}
                    >
                        {options.map((option, i) => <Option
                            key={i} value={option}
                            onClick={value => {
                                setShowOptions(false)
                                setValue(value)
                                onChange(type[value])
                            }}
                        />)}
                    </div>
                </>
            }
        </div>
    </>
}

const Option = ({value, onClick}) => <>
    <div
        style={{
            width: 'calc(100% - 33px)',
            paddingLeft: 33,
            height: 54,
            display: 'flex', flexFlow: 'row nowrap',
            alignItems: 'center'
        }}
        onClickCapture={() => onClick(value)}
    >
        {value}
    </div>
</>

export default Dropdown