import { AnimatePresence, motion } from "framer-motion";

const Text = ({visible = true, children, style, animated = false, duration = 1, delay=0.5}) => {
    const variants = {
        initial: {opacity: 0},
        animate: {opacity: 1},
        exit: {opacity: 0}
    }

    return (animated &&
            <AnimatePresence>
                {visible && (
                    <motion.p
                        variants={variants}
                        style={{...style}}
                        initial={"initial"}
                        animate={"animate"}
                        exit={"exit"}
                        transition={{duration: duration, delay: delay, ease: "anticipate"}}
                    >
                        {children}
                    </motion.p>
                )}
            </AnimatePresence>
        ) ||
        <p style={{...style}}>
            {children}
        </p>
}

export default Text