import { useContext, useEffect, useState } from "react"
import { motion } from "framer-motion"
import Text from "./text/Text"
import { ScaleContext } from "../utils/Scale";

const RatingHandle = ({positions, value, setValue, setExactValue}) => {
    const [snapPoints, setSnapPoints] = useState(positions)
    const [label, setLabel] = useState(null)
    const [cumulativeDelta, setCumulativeDelta] = useState(0)
    const {scale} = useContext(ScaleContext)

    useEffect(() => {
        setSnapPoints(positions.map(pos => pos * scale - 38 * scale))
    }, [positions])

    useEffect(() => {
        setLabel(value)
    }, [value])

    const onDragEnd = () => {
        setValue(label)
        setExactValue((snapPoints[label] - snapPoints[0]) / (snapPoints[10] - snapPoints[0]))
        setCumulativeDelta(0)
    }

    const onDrag = (event, info) => {
        // calculate label
        setCumulativeDelta(prev => prev + info.delta.x)
        const testPoint = snapPoints[value] + cumulativeDelta
        const closestPoint = snapPoints.reduce((a, b) => Math.abs(b - testPoint) < Math.abs(a - testPoint) ? b : a)
        const closestPointIndex = snapPoints.indexOf(closestPoint)
        setLabel(closestPointIndex)

        let exactValue = (testPoint - snapPoints[0]) / (snapPoints[10] - snapPoints[0])
        if (exactValue < 0) exactValue = 0
        if (exactValue > 1) exactValue = 1
        setExactValue(exactValue)
    }

    return <motion.div
        style={{
            position: 'absolute', top: -102 * 1.1 * scale, left: snapPoints[value] - 150 / 4 * scale, width: 150 * scale, height: 200 * scale,
            display: 'flex', justifyContent: 'center', alignItems: 'center'
        }}
        drag={'x'}
        dragConstraints={{left: -(snapPoints[value] - snapPoints[0]), right: -(snapPoints[value] - snapPoints[10])}}
        dragElastic={0}
        dragMomentum={false}
        dragSnapToOrigin={true}
        onDragEnd={onDragEnd}
        onDrag={onDrag}
    >
        <svg width={68 * 1.1 * scale} viewBox="0 0 68 98" preserveAspectRatio={"xMidYMid meet"} fill="none" xmlns="http://www.w3.org/2000/svg" style={{pointerEvents: "none"}}>
            <path fillRule="evenodd" clipRule="evenodd" d="M14 0C6.26801 0 0 6.26801 0 14V34C0 41.732 6.26801 48 14 48H15C20.6661 48 25.265 52.5559 25.265 58.1861C25.265 60.9821 24.1364 63.6943 22.0481 65.5837C18.1862 69.0731 15.8369 74.1852 16.1056 79.8382C16.5355 88.9805 23.9828 96.4924 33.1807 97.0562C43.7912 97.7114 52.6051 89.369 52.6051 78.9773C52.6051 73.6899 50.3249 68.9359 46.678 65.6218C44.582 63.7248 43.4303 61.0126 43.4303 58.1937C43.4303 52.5712 48.0216 48.0076 53.6953 48.0076L53.703 48H54C61.732 48 68 41.732 68 34V14C68 6.26801 61.732 0 54 0H14Z" fill="#1E0B46"/>
        </svg>
        <Text
            style={{
                color: "var(--white)", margin: 0, fontWeight: 600, fontSize: 30 * scale, textAlign: 'center',
                position: 'absolute', width: 40 * scale, top: 53 * scale, left: 55 * scale
            }}
        >
            {label}
        </Text>
    </motion.div>
}

export default RatingHandle