import { createContext, useContext, useEffect, useState } from "react"

const colours = {
    white: "#FFFFFF",
    grey: "#E8E8E8",
    dark: "#1E0B46",
    dark15: "#1E0B4626",
    accent: "#8375DA",
    light: "#EFECFF",
    light2: "#D3CBFF",
    gradient1A: "#B2A4FF",
    gradient1B: "#8375DA",
    paragraph: "#626262",
    paragraph50: "#6262627F",
    paragraph30: "#6262624C",
    shadow1: "#B5A3FE80",
    error: "#FFFFFF"
}

const setCSSVariables = theme => {
    for (const value in theme) {
        document.documentElement.style.setProperty(`--${value}`, theme[value])
    }
}

const ThemeSelector = createContext({name: 'no theme'})

const Themes = ({children}) => {
    const [theme, setTheme] = useState(colours)

    useEffect(() => setCSSVariables(theme))

    return (
        <ThemeSelector.Provider value={{theme: theme}}>
            {children}
        </ThemeSelector.Provider>
    )
}

const useTheme = () => {
    const {theme} = useContext(ThemeSelector)
    return theme
}

export default Themes
export { useTheme }