import flower00 from "../images/Flower 00.png"
import flower01 from "../images/Flower 01.png"
import flower02 from "../images/Flower 02.png"
import flower03 from "../images/Flower 03.png"
import flower04 from "../images/Flower 04.png"
import flower05 from "../images/Flower 05.png"
import flower06 from "../images/Flower 06.png"
import flower07 from "../images/Flower 07.png"
import flower08 from "../images/Flower 08.png"
import flower09 from "../images/Flower 09.png"
import flower10 from "../images/Flower 10.png"
import Text from "./text/Text"
import { AnimatePresence, motion } from "framer-motion"
import { useContext } from "react";
import { ScaleContext } from "../utils/Scale";

const flowers = [flower00, flower01, flower02, flower03, flower04, flower05, flower06, flower07, flower08, flower09, flower10]
const colours = ['#FFA1A1', '#FFBF90','#FCF297','#FFA1A1','#ABF9A5','#80F9CE','#9DE7FF','#97C3F6','#B9A0FF','#DB97FB','#FF99DD']

const ResponseCount = ({label, count, percent, visualPercent, visible}) => {
    const {scale} = useContext(ScaleContext)

    return <>
        <AnimatePresence>
            {
                visible &&
                <motion.div
                    key={label}
                    style={{
                        display: 'flex', flexFlow: 'row nowrap',
                        height: 50 * scale, width: '100%',
                        alignItems: 'center', justifyContent: 'flex-start',
                        gap: 22 * scale,
                        marginBottom: 15 * scale
                    }}
                    variants={{
                        initial: {
                            opacity: 0,
                            transform: 'translateY(25px)'
                        },
                        animate: {
                            opacity: 1,
                            transform: 'translateY(0px)'
                        },
                        exit: {
                            opacity: 0,
                            transform: 'translateY(25px)'
                        }}}
                >
                    <img src={flowers[label]} width={35 * scale} height={"auto"}/>
                    <div
                        style={{
                            display: 'flex', flexFlow: 'column nowrap',
                            height: '100%', flexGrow: 1,
                            alignItems: 'flex-start', justifyContent: 'space-between'
                        }}
                    >
                        <Text style={{color: "var(--dark)", margin: 0, fontSize: 16 * scale, lineHeight: `calc(24px * ${scale})`, fontWeight: 700}}>{label}</Text>
                        <div
                            style={{
                                width: '100%', height: 6 * scale,
                                background: '#E8E8E8',
                                borderRadius: 3 * scale
                            }}
                        >
                            <motion.div
                                style={{
                                    width: '0%',
                                    height: 6 * scale,
                                    background: colours[label],
                                    borderRadius: 3 * scale
                                }}
                                animate={{width: visualPercent + '%', transition: {delay: 0.75 + label * 0.1}}}
                            />
                        </div>
                        <div
                            style={{
                                display: 'flex', flexFlow: 'row nowrap',
                                alignItems: 'center', justifyContent: 'space-between',
                                width: '100%'
                            }}
                        >
                            <Text style={{color: "var(--paragraph)", margin: 0, fontSize: 10.24 * scale, lineHeight: `calc(15px * ${scale})`}}>{count} responses</Text>
                            <Text style={{color: "var(--paragraph)", margin: 0, fontSize: 10.24 * scale, lineHeight: `calc(15px * ${scale})`}}>{percent}%</Text>
                        </div>
                    </div>
                </motion.div>
            }
        </AnimatePresence>
    </>
}

export default ResponseCount