import Home from "./pages/Home"
import './App.css'
import Themes from "./Themes"
import { Auth } from "./utils/firebase"
import { useEffect, useState } from "react"
import { getAuth, signOut } from "firebase/auth"
import { useAuthState } from "react-firebase-hooks/auth"
import GatherResponse from "./container/GatherResponse"
import Administrate from "./container/Administrate"
import Cookies from "./utils/cookies"
import HomeBackground from "./pages/HomeBackground"
import Scale from "./utils/Scale";

const App = () => {
    const [administrate, setAdministrate] = useState(false)
    const [gatherResponse, setGatherResponse] = useState(false)
    const [height, setHeight] = useState(0)
    const [width, setWidth] = useState(0)
    const [viewport, setViewport] = useState({ width: 1, height: 1, screen: { width: 1, height: 1 }, target: { width: 428, height: 926 }, scale: 1, scaleW: 1 })

    const auth = getAuth()
    const authState = useAuthState(auth)
    const [signedIn, setSignedIn] = useState(false)

    useEffect(() => {
        setTimeout(() => {
            setInterval(() => {
                handleWindowDimensions()
            }, 1000)
        }, 1000)
    }, [])

    useEffect(() => {
        handleWindowDimensions()
    })

    const handleWindowDimensions = () => {
        if (window.innerHeight !== height) setHeight(window.innerHeight)
        if (window.innerWidth !== width) setWidth(window.innerWidth)
    }

    useEffect(() => {
        setViewport({
            width: width / height > 0.9 ? (height / 16 * 9) : width,
            height: height,
            screen: {
                width: width,
                height: height
            },
            target: viewport.target,
            scale: height / viewport.target.height,
            scaleW: width / viewport.target.width
        })
    }, [height, width])

    useEffect(() => {
        setSignedIn(!!authState[0]?.uid)
    }, [authState])

    return <>
        <Cookies>
            <Auth>
                <Themes>
                    <Scale scale={viewport.scale} scaleW={viewport.scaleW}>
                        <div style={{
                            width: width, height: height,
                            background: 'var(--light)',
                            display: 'flex',
                            justifyContent: 'center'
                        }}>
                            <HomeBackground viewport={viewport} />
                            <div
                                style={{
                                    position: 'absolute',
                                    top: 0, left: viewport.screen.width / 2 - viewport.width / 2,
                                    width: viewport.width, height: viewport.height,
                                    overflow: 'hidden'
                                }}
                            >
                                <Home
                                    onAdmin={() => {
                                        setAdministrate(true)
                                    }}
                                    onRespond={() => {
                                        setGatherResponse(true)
                                    }}
                                    signedIn={signedIn}
                                    signOut={() => signOut(auth)}
                                    viewport={viewport}
                                />
                            </div>
                            <div
                                style={{
                                    pointerEvents: 'none',
                                    position: 'absolute',
                                    top: 0, left: (width - viewport.width) / 2,
                                    width: viewport.width, height: viewport.height,
                                    overflow: 'hidden'
                                }}
                            >
                                <GatherResponse visible={gatherResponse} viewport={viewport} onHome={() => setGatherResponse(false)} />
                            </div>
                            <div
                                style={{
                                    pointerEvents: 'none',
                                    position: 'absolute',
                                    top: 0, left: (width - viewport.width) / 2,
                                    width: viewport.width, height: viewport.height,
                                    overflow: 'hidden'
                                }}
                            >
                                <Administrate visible={administrate} viewport={viewport} signedIn={signedIn} onHome={() => setAdministrate(false)} />
                            </div>
                        </div>
                    </Scale>
                </Themes>
            </Auth>
        </Cookies>
    </>
}

export default App