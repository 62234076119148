import { useContext } from "react";
import { ScaleContext } from "../utils/Scale";

const Input = ({label, placeholder, style, type = "text", value = '', onChange, labelStyle, inputStyle, onFocus = () => {}, onBlur = () => {}}) => {
    const {scale} = useContext(ScaleContext)
    return <>
        <div
            style={{
                width: '100%',
                height: 82 * scale,
                display: 'flex',
                flexFlow: 'column nowrap',
                justifyContent: 'space-between',
                ...style
            }}
        >
            <label
                htmlFor={label}
                style={{height: 22 * scale, color: 'var(--white)', fontWeight: 600, lineHeight: '150%', ...labelStyle}}
            >
                {label}
            </label>
            {
                type === 'multi' && <>
                    <textarea
                        name={label}
                        value={value}
                        onChange={e => onChange(e.target.value)}
                        maxLength={120}
                        placeholder={placeholder}
                        style={{
                            fontFamily: 'Poppins, sans-serif',
                            fontSize: 16 * scale,
                            color: 'var(--dark)',
                            borderRadius: 10 * scale,
                            padding: 10 * scale,
                            height: 227 * scale,
                            border: '1.5px solid #E8E8E8',
                            resize: 'none',
                            ...inputStyle
                        }}
                        onFocus={onFocus}
                        onBlur={onBlur}
                    />
                </>
            }
            {
                type === 'single' && <>
                    <input
                        name={label}
                        value={value}
                        onChange={e => onChange(e.target.value)}
                        maxLength={120}
                        placeholder={placeholder}
                        style={{
                            fontFamily: 'Poppins, sans-serif',
                            fontSize: 16 * scale,
                            color: 'var(--dark)',
                            borderRadius: 10 * scale,
                            padding: 10 * scale,
                            border: '1.5px solid #E8E8E8',
                            resize: 'none',
                            ...inputStyle
                        }}
                        onFocus={onFocus}
                        onBlur={onBlur}
                    />
                </>
            }
            {
                type !== 'multi' && type !== 'single' && <>
                    <input
                        value={value}
                        onChange={e => onChange(e.target.value)}
                        type={type}
                        id={label}
                        name={label}
                        placeholder={placeholder}
                        style={{
                            height: 50 * scale, width: `calc(100% - ${16 * scale}px)`, paddingLeft: 16 * scale,
                            backgroundImage: 'none', background: 'var(--white)',
                            borderRadius: 10 * scale, border: 'none',
                            color: 'var(--dark)',
                            display: 'flex', alignItems: 'center',
                            fontFamily: 'Poppins, sans-serif', fontSize: 16 * scale,
                            ...inputStyle
                        }}
                        onFocus={onFocus}
                        onBlur={onBlur}
                    />
                </>
            }
        </div>
    </>
}

export default Input