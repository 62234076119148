import Page from "../pages/Page"
import SignIn from "../pages/SignIn"
import AdminView from "../pages/AdminView"
import Card from "../cards/Card"
import AddProvocation from "../cards/AddProvocation"
import ViewProvocation from "../pages/ViewProvocation"
import Bottombar from "./Bottombar"
import { useContext, useEffect, useState } from "react"
import { getAuth } from "firebase/auth"
import { db } from "../utils/firebase"
import { collection, doc, getCountFromServer, getDoc, onSnapshot, query, where } from "firebase/firestore"
import { ScaleContext } from "../utils/Scale"
import SquiggleBackground from "../pages/SquiggleBackground"

const Administrate = ({ visible, viewport, signedIn, onHome }) => {
    const auth = getAuth()
    const [userData, setUserData] = useState({})
    const [page, setPage] = useState(0)
    const [addProvocation, setAddProvocation] = useState(false)
    const [viewProvocation, setViewProvocation] = useState(null)
    const [viewProvocationData, setViewProvocationData] = useState(null)
    const [provocations, setProvocations] = useState([])
    const { scale } = useContext(ScaleContext)
    const [forceActiveLive, setForceActiveLive] = useState({})
    const [forceActiveDev, setForceActiveDev] = useState({})

    useEffect(() => {
        if (signedIn) {
            onSnapshot(doc(db, "users", 'lmr8FH8ZmBXF8dAHMKLEJOI5z8w1'), doc => {
                const data = doc.data()
                setForceActiveLive({
                    end: data.forceActive.at.toDate().setSeconds(data.forceActive.at.toDate().getSeconds() + data.forceActiveTime),
                    id: data.forceActive.id
                })
            })
        }
    }, [JSON.stringify(auth), page])

    useEffect(() => {
        if (signedIn) {
            onSnapshot(doc(db, "users", 'IdgI7pMAzrfR7EVTurnqgyGJ7te2'), doc => {
                const data = doc.data()
                setForceActiveDev({
                    end: data.forceActive.at.toDate().setSeconds(data.forceActive.at.toDate().getSeconds() + data.forceActiveTime),
                    id: data.forceActive.id
                })
            })
        }
    }, [JSON.stringify(auth), page])

    useEffect(() => {
        setViewProvocationData(provocations.filter(provocation => provocation.id === viewProvocation)[0])
    }, [viewProvocation, provocations])

    useEffect(() => {
        if (visible) setPage(signedIn ? 2 : 1)
        else setPage(0)
    }, [visible, signedIn])

    useEffect(() => {
        if (auth?.currentUser?.uid) {
            getDoc(doc(db, "users", auth.currentUser.uid))
                .then(snapshot => {
                    if (snapshot.exists()) {
                        setUserData({ ...snapshot.data() })
                    }
                })
        }
    }, [JSON.stringify(auth)])

    useEffect(() => {
        page === 2 &&
            onSnapshot(collection(db, "provocations"), snapshots => {
                snapshots.forEach(doc => {
                    const data = doc.data()
                    let provocation = {
                        id: doc.id,
                        allowCommunityResponses: data.allowCommunityResponses,
                        prompt: data.prompt,
                        type: {
                            min: data.type.min,
                            max: data.type.max,
                            display: data.type.display
                        },
                        resetResponses: data.resetResponses && data.resetResponses.length > 0
                            ? new Date(Math.max(...data.resetResponses.map(date => date.toDate())))
                            : new Date('2020-01-01'),
                        dev: data?.dev,
                        deleted: data?.deleted
                    }
                    getCountFromServer(
                        query(
                            collection(db, `provocations/${provocation.id}/responses`),
                            where("created", ">", provocation.resetResponses)))
                        .then(snapshot => {
                            provocation.responseCount = snapshot.data().count
                            setProvocations(prevArray => {
                                let newArray = [provocation]

                                for (const prev of prevArray) {
                                    if (prev.id !== provocation.id) newArray.push(prev)
                                }

                                return [...newArray]
                            })
                        })
                })
            })
    }, [JSON.stringify(auth), page])

    return <>
        <Page
            visible={visible}
            onBack={onHome}
            onHome={onHome}
            background={"linear-gradient(to bottom, var(--gradient1A), var(--gradient1B)"}
            width={viewport.width}
            height={viewport.height}
            topbar={'var(--white)'}
            paddingSides={50 * scale}
        >
            <SignIn onContinue={() => setPage(2)} viewport={viewport} />
        </Page>
        <Page
            visible={visible && signedIn && page >= 2}
            onBack={() => addProvocation ? setAddProvocation(false) : onHome()}
            onHome={() => {
                onHome()
                setAddProvocation(false)
            }}
            width={viewport.width}
            height={viewport.height}
            paddingSides={35 * scale}
            heading={userData?.username}
        >
            <AdminView
                add={() => setAddProvocation(true)}
                view={(id) => {
                    setViewProvocation(id);
                    setPage(3);
                }}
                provocations={provocations.filter(provocation => !!!provocation.deleted && (!!userData?.dev ? true : !!!provocation.dev))}
            />
            <Card viewport={viewport} top={103 * scale} visible={addProvocation}>
                <AddProvocation
                    close={() => setAddProvocation(false)}
                    goToProvocation={(id) => {
                        setAddProvocation(false)
                        setViewProvocation(id)
                        setPage(3)
                    }}
                    isDev={!!userData?.dev}
                />
            </Card>
        </Page>
        <Page
            visible={visible && signedIn && page >= 3}
            onBack={() => setPage(2)}
            onHome={onHome}
            width={viewport.width}
            height={viewport.height}
            paddingSides={50 * scale}
        >
            <ViewProvocation data={viewProvocationData} viewport={viewport} />
            <Bottombar viewport={viewport} provocation={viewProvocation} data={viewProvocationData} forceActive={{ live: { ...forceActiveLive }, dev: { ...forceActiveDev } }} onDelete={() => setPage(2)} />
        </Page>
    </>
}

export default Administrate