import { AnimatePresence, motion } from "framer-motion"
import { useContext, useEffect, useState } from "react";
import Dimmer from '../components/Dimmer'
import { ScaleContext } from "../utils/Scale";

const baseAnimation = {
    initial: {
        transform: 'translate(0, 100%)'
    },
    animate: {
        transform: 'translate(0, 0%)'
    },
    exit: {
        transform: 'translate(0, 100%)'
    },
    transition: {
        duration: 0.2,
        ease: "easeInOut"
    }
}

const Card = ({viewport, top, visible, dimmer = false, dimmerClick, children, unscaledPadding = {top: 40, right: 56, bottom: 30, left: 56}, initial, animate, exit, transition}) => {
    const [animation, setAnimation] = useState(baseAnimation)
    const {scale} = useContext(ScaleContext)
    const [scaledPadding, setScaledPadding] = useState(unscaledPadding)

    useEffect(() => setAnimation({
        initial: initial || baseAnimation.initial,
        animate: animate || baseAnimation.animate,
        exit: exit || baseAnimation.exit,
        transition: transition || baseAnimation.transition
    }), [initial, animate, exit, transition])

    useEffect(() => {
        setScaledPadding({
            top: unscaledPadding.top * scale,
            right: unscaledPadding.right * scale,
            bottom: unscaledPadding.bottom * scale,
            left: unscaledPadding.left * scale,
        })
    }, [JSON.stringify(unscaledPadding)])

    return <>
        <AnimatePresence>
            {dimmer && visible && <Dimmer key={"dimmer"} click={dimmerClick}/>}
            {
                visible &&
                <motion.div
                    style={{
                        position: 'absolute',
                        bottom: 0, left: 0,
                        overflow: 'hidden',
                        display: 'flex',
                        flexFlow: 'column nowrap',
                        alignItems: 'center',
                        pointerEvents: 'auto',
                        width: 'calc(' + viewport.width + 'px - ' + (scaledPadding.left + scaledPadding.right) + 'px)',
                        height: 'calc(' + viewport.height + 'px - ' + top + 'px - ' + scaledPadding.top + 'px - ' + scaledPadding.bottom + 'px)',
                        padding: scaledPadding.top + 'px ' + scaledPadding.right + 'px ' + scaledPadding.bottom + 'px ' + scaledPadding.left + 'px',
                        background: 'var(--white)',
                        borderRadius: `${20 * scale}px ${20 * scale}px ${0}px ${0}px`,
                        boxShadow: '0 -4px 25px 0 #0000000A'
                    }}
                    variants={animation}
                    initial={"initial"}
                    animate={"animate"}
                    exit={"exit"}
                    transition={"transition"}
                    /*
                    initial={animation.initial}
                    animate={animation.animate}
                    exit={animation.exit}
                    transition={animation.transition}
                    */
                >
                    {children}
                </motion.div>
            }
        </AnimatePresence>
    </>
}

export default Card