import { AnimatePresence, motion } from "framer-motion";

const style = {
    initial: {
        background: `rgba(0, 0, 0, 0)`,
        backdropFilter: `blur(0px) saturate(100%)`,
        WebkitBackdropFilter: `blur(0px) saturate(100%)`,
        pointerEvents: 'none',
        cursor: 'pointer',
        position: 'fixed',
        top: '50%',
        left: '50%',
        transform: `translate(-50%, -50%)`,
        width: '120vw',
        height: '120vh'
    },
    animate: {
        background: `rgba(0, 0, 0, 0.5)`,
        backdropFilter: `blur(1.5px) saturate(85%)`,
        WebkitBackdropFilter: `blur(1.5px) saturate(85%)`,
        pointerEvents: 'auto'
    }
}
const Dimmer = ({click}) => {
    return <>
        <motion.div
            initial={{
                ...style.initial
            }}
            animate={{
                ...style.animate
            }}
            exit={{
                ...style.initial
            }}
            onClick={() => click()}
        >
        </motion.div>
    </>
}

export default Dimmer