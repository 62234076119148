import AddProvocation from "../components/AddProvocation"
import { useContext, useEffect, useState } from "react"
import ProvocationSummary from "../components/ProvocationSummary"
import { db } from "../utils/firebase";
import { collection, getCountFromServer, onSnapshot, Timestamp } from "firebase/firestore";
import { ScaleContext } from "../utils/Scale";

const AdminView = ({add, view, provocations}) => {
    const {scale} = useContext(ScaleContext)

    return <div
        style={{
            width: '100%',
            height: `calc(100% - (20px + 48px) * ${scale})`,
            marginTop: 20 * scale,
            overflow: "visible"
        }}
    >
        <AddProvocation onClick={() => add()}/>
        <div
            style={{
                marginTop: 35 * scale,
                width: '200%',
                display: 'flex',
                flexFlow: 'column nowrap',
                gap: 15 * scale,
                scrollBehavior: "smooth",
                overflowY: "auto",
                overflowX: "hidden",
                paddingRight: 100 * scale,
                marginLeft: -20 * scale,
                height: `calc(100% - (260px + 35px + 20px) * ${scale})`,
                paddingBottom: 20  * scale
            }}
        >
            {
                [...provocations]
                    .sort((a, b) => a.prompt > b.prompt ? 1 : -1)
                    .map(data =>
                    <ProvocationSummary
                        key={data.id}
                        community={data.allowCommunityResponses}
                        prompt={data.prompt}
                        responses={data.responseCount}
                        dev={data.dev}
                        onClick={() => view(data.id)}
                    />)
            }
        </div>
    </div>
}

export default AdminView