import Icon, { Arrow, Home } from "../components/Icon"
import Text from "../components/text/Text"
import { useContext, useEffect, useState } from "react";
import { ScaleContext } from "../utils/Scale";

const Topbar = ({children, color, style, onBack, onHome, paddingSides = 0, navigationEnabled = true}) => {
    const [styles, setStyles] = useState({})
    const {scale} = useContext(ScaleContext)

    const baseStyle = {
        display: 'flex',
        flexFlow: 'row nowrap',
        alignItems: 'center',
        justifyContent: 'space-between'
    }

    useEffect(() => {
        setStyles({
            ...baseStyle,
            height: 48 * scale,
            ...style,
            width: 'calc(100% - ' + paddingSides * 2 + 'px)',
            paddingLeft: paddingSides, paddingRight: paddingSides
        })
    }, [style, paddingSides])

    return <>
        <div style={styles}>
            <Icon onClick={() => navigationEnabled && onBack()}>
                <Arrow direction={'left'} color={color} />
            </Icon>
            <Text style={{color: color, fontSize: 25 * scale, fontWeight: 600}}>{children}</Text>
            <Icon onClick={() => navigationEnabled && onHome()}>
                <Home color={color} />
            </Icon>
        </div>
    </>
}

export default Topbar