import Icon, { Community, Lock, Tick } from "./Icon";
import { useContext } from "react";
import { ScaleContext } from "../utils/Scale";

const Checkbox = ({value, onChange, backgroundColor, tickColor, children, dev = false}) => {
    const {scale} = useContext(ScaleContext)
    return <>
        <div
            onClick={() => onChange(!value)}
            style={{
                color: 'var(--paragraph)',
                width: '100%',
                height: 54 * scale,
                display: 'flex',
                flexFlow: 'row nowrap',
                justifyContent: 'space-between',
                alignItems: 'center',
                borderBottom: '1px solid #E8E8E8'
            }}
        >
            <div style={{display: 'flex', flexFlow: 'row nowrap', justifyContent: 'flex-start', alignItems: 'center', fontSize: 16 * scale}}>
                <Icon style={{width: 26 * scale, height: 26 * scale, marginRight: 10 * scale}}>
                    {!dev && <Community solid={false} color={'var(--paragraph)'}/>}
                    {dev && <Lock solid={false} color={'var(--paragraph'}/>}
                </Icon>
                {children}
            </div>
            <div
                style={{
                    display: 'flex',
                    alignItems: 'center', justifyContent: 'center',
                    width: 20 * scale, height: 20 * scale,
                    borderRadius: 5 * scale,
                    background: backgroundColor,
                    marginRight: 20 * scale
                }}
            >
                {
                    value &&
                    <Tick
                        color={tickColor}
                        style={{
                            width: 8.87 * scale, height: 6.49 * scale
                        }}
                    />
                }
            </div>
        </div>
    </>
}

export default Checkbox