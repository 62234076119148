import Page from "../pages/Page"
import Text from "../components/text/Text"
import Button, { ButtonThemes } from "../components/Button"
import PinCode from "../components/PinCode"
import ShareResponse from "../components/ShareResponse"
import { useContext, useEffect, useMemo, useState } from "react"
import { db, functions } from "../utils/firebase"
import { getDoc, doc } from 'firebase/firestore'
import { useHttpsCallable } from "react-firebase-hooks/functions"
import { CookieContext } from "../utils/cookies"
import { ScaleContext } from "../utils/Scale";
import Squiggle from '../images/SquiggleAccent50.png'

const GatherResponse = ({visible, viewport, onHome}) => {
    const [page, setPage] = useState(1)
    const [response, setResponse] = useState({})
    const [code, setCode] = useState('')
    const [getProvocationIdFromCode, getProvocationIdFromCodeLoading] = useHttpsCallable(functions, 'getProvocationIdFromCode')
    const [addResponse, addResponseLoading] = useHttpsCallable(functions, 'addResponse')
    const [provocation, setProvocation] = useState(null)
    const cookies = useContext(CookieContext)
    const {scale} = useContext(ScaleContext)

    const padScale = useMemo(() => scale * 1.1, [scale])

    useEffect(() => {
        page === 3 && setCode('')
    }, [page])

    useEffect(() => {
        if (code && code.length === 4 && !getProvocationIdFromCodeLoading) {
            getProvocationIdFromCode({code: code})
                .then(response => {
                    if (page === 3 && response?.data) {
                        let id = response.data.trim()
                        getDoc(doc(db, 'provocations', id))
                            .then(doc => {
                                if (page === 3 && doc.exists) {
                                    setProvocation({id, ...doc.data()})
                                    setPage(4)
                                } else setCode('')
                            })
                    } else setCode('')
                })
        }
    }, [code])

    const handleSubmit = () => {
        addResponse({code: code, ids: {provocation: provocation.id, response: cookies.Get(provocation.id) || ''}, response: {...response}})
            .then(result => {
                result?.data?.id && cookies.Add(provocation.id, result.data.id)
                result && onHome()
            })
    }

    const addToResponse = (data, page = null) => {
        let newResponse = {...response, ...data}
        if (newResponse?.role !== 'student') delete newResponse['yearLevel']

        setResponse(newResponse)
        if (page !== null) setPage(page)
    }

    useEffect(() => {
        setPage(visible ? 1 : 0)
        visible && setResponse({})
    }, [visible])

    return <>
        <Page
            visible={visible && page >= 1}
            onBack={onHome}
            onHome={onHome}
            width={viewport.width}
            height={viewport.height}
            paddingSides={50 * scale}
            heading=''
        >
            <div
                style={{marginTop: 72 * scale}}
            >
                <Text style={{color: "var(--dark)", margin: 0, fontWeight: 600, fontSize: 30 * scale, lineHeight: '120%', width: '100%'}}>What best describes you?</Text>
                <Text style={{color: "var(--paragraph)", margin: 0, marginTop: 6, fontSize: 18 * scale, lineHeight: '150%', width: '100%'}}>Please select from the options below</Text>
            </div>
            <div
                style={{
                    width: '100%', marginTop: 112 * scale,
                    display: 'flex', flexFlow: 'column nowrap',
                    gap: 15 * scale
                }}
            >
                <Button theme={ButtonThemes.Dark} onClick={() => addToResponse({role: 'student'}, 2)}>I am a student</Button>
                <Button theme={ButtonThemes.Dark} onClick={() => addToResponse({role: 'parent'}, 3)}>I am a parent of a student</Button>
                <Button theme={ButtonThemes.Dark} onClick={() => addToResponse({role: 'staff'}, 3)}>I am a staff member</Button>
                <Button theme={ButtonThemes.Dark} onClick={() => addToResponse({role: 'visitor'}, 3)}>I am a visitor</Button>
            </div>
        </Page>
        <Page
            visible={visible && page >= 2}
            onBack={() => setPage(1)}
            onHome={onHome}
            width={viewport.width}
            height={viewport.height}
            paddingSides={50 * scale}
            heading={response?.role || ''}
        >
            <div
                style={{marginTop: 72}}
            >
                <Text style={{color: "var(--dark)", margin: 0, fontWeight: 600, fontSize: 30 * scale, lineHeight: '120%', width: '100%'}}>What year level are you?</Text>
                <Text style={{color: "var(--paragraph)", margin: 0, marginTop: 6 * scale, fontSize: 18 * scale, lineHeight: '150%', width: '100%'}}>Please select from the options below</Text>
            </div>
            {
                [...Array(4).keys()].map(i => {
                    return (
                        <div
                            key={i}
                            style={{
                                width: 270 * padScale, marginTop: i === 0 ? 112 * scale : 30 * scale,
                                display: 'flex', flexFlow: 'row nowrap',
                                gap: 30 * padScale, padding: '0 calc((100% - (270px * ' + padScale + ')) / 2)'
                            }}
                        >
                            {
                                [...Array(3).keys()].map(j => {
                                    j++
                                    const value = i * 3 + j
                                    return (
                                        <Button key={value} keypad={true} theme={ButtonThemes.Light} style={{height: 70 * padScale, width: 70 * padScale}}
                                                onClick={() => addToResponse({yearLevel: value}, 3)}>{value}</Button>
                                    )
                                })
                            }
                        </div>
                    )
                })
            }
        </Page>
        <Page
            visible={visible && page >= 3}
            onBack={() => setPage(1)}
            onHome={onHome}
            background={"linear-gradient(to bottom, var(--gradient1A), var(--gradient1B)"}
            width={viewport.width}
            height={viewport.height}
            topbar={'var(--white)'}
            paddingSides={50 * scale}
            heading={response?.role || ''}
            topNavigationEnabled={code.length < 4}
        >
            <img src={Squiggle} style={{
                mixBlendMode: 'screen',
                position: 'absolute',
                top: -116 * viewport.scale, left: viewport.width / 2 - 723 * viewport.scale * 0.75 / 2,
                width: 723 * viewport.scale, height: 1304 * viewport.scale,
                zIndex: -1
            }}/>
            <PinCode viewport={viewport} code={code} setCode={setCode}/>
        </Page>
        <Page
            visible={visible && page >= 4}
            onBack={() => setPage(3)}
            onHome={onHome}
            width={viewport.width}
            height={viewport.height}
            paddingSides={50 * scale}
            heading={response?.role || ''}
            topNavigationEnabled={!addResponseLoading}
        >
            <ShareResponse provocation={provocation} addToResponse={(data) => addToResponse(data)} submit={() => !addResponseLoading && handleSubmit()} loading={addResponseLoading} viewport={viewport}/>
        </Page>
    </>
}

export default GatherResponse