import Text from "../components/text/Text"
import Squiggle from '../images/SquiggleAccent50.png'
import Character from "../images/Character.png"
import { AnimatePresence, motion } from "framer-motion"
import { useEffect, useState } from "react";

const characterAnims = {
    initial: {opacity: 0},
    animate: {opacity: 1},
    exit: {opacity: 0}
}

const HomeBackground = ({viewport}) => {
    const [squiggleWidth, setSquiggleWidth] = useState(723)
    const [characterWidth, setCharacterWidth] = useState(641)

    useEffect(() => {
        setSquiggleWidth(723 * viewport.scale)
    }, [viewport])

    useEffect(() => {
        setCharacterWidth(641 * viewport.scale)
    }, [viewport])

    return <div
        style={{
            position: 'absolute',
            background: 'var(--light2)',
            width: `${viewport.width}px`,
            height: `${viewport.height}px`,
            top: 0, left: `${viewport.screen.width / 2 - viewport.width / 2}px`,
            overflow: "hidden"
        }}
    >
        <img src={Squiggle} style={{
            mixBlendMode: 'screen',
            position: 'relative',
            top: -116 * viewport.scale, left: viewport.width / 2 - squiggleWidth * 0.75 / 2,
            width: squiggleWidth, height: 1304 * viewport.scale
        }}/>
        <AnimatePresence>
            <motion.img
                src={Character}
                style={{
                    position: 'relative',
                    top: -1273 * viewport.scale, left: viewport.width / 2 - characterWidth * 0.6 / 2,
                    width: characterWidth, height: 553 * viewport.scale
                }}
                variants={characterAnims}
                initial={"initial"}
                animate={"animate"}
                exit={"exit"}
                transition={{duration: 2, delay: 0, ease: "anticipate"}}
            />
        </AnimatePresence>
        <div
            style={{
                position: 'relative',
                top: -1687 * viewport.scale, left: viewport.width / 2 - 140 * viewport.scale
            }}
        >
            <Text style={{
                color: "var(--dark)",
                fontSize: 16 * viewport.scale, lineHeight: '150%'
            }} animated={true} visible={true} delay={1} duration={2}>Welcome to the</Text>
            <Text style={{
                color: "var(--dark)",
                fontSize: 31.25 * viewport.scale,
                fontWeight: 600,
                lineHeight: '100%',
                marginTop: -20 * viewport.scale
            }} animated={true} visible={true} delay={1} duration={2}>Provocation<br/>Garden</Text>
        </div>
    </div>
}

export default HomeBackground