import ResponseCount from "./ResponseCount"
import Card from "../cards/Card"
import { useContext, useEffect, useState } from "react"
import { ScaleContext } from "../utils/Scale";

const animation = {
    animate: {
        transform: 'translate(0, 0%)',
        transition: {
            delay: 0.4,
            delayChildren: 0.4,
            staggerChildren: 0.08
        }
    }
}

const ResponseCountList = ({viewport, visible, responses}) => {
    const [responseCounts, setResponseCounts] = useState([])
    const {scale} = useContext(ScaleContext)

    useEffect(() => {
        let newResponseCounts = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
        responses.forEach(response => newResponseCounts[response] += 1)
        setResponseCounts([...newResponseCounts])
    }, [responses, visible])

    return <>
        <Card
            viewport={viewport} top={416 * scale} unscaledPadding={{top: 32, right: 73, bottom: 32, left: 73}}
            visible={visible}
            animate={animation.animate}
        >
            <div
                style={{
                    width: '100%',
                    height: '100%',
                    overflow: "visible"
                }}
            >
                <div
                    style={{
                        width: '100%',
                        scrollBehavior: "smooth",
                        overflowY: "auto",
                        overflowX: "hidden",
                        height: `calc(100% - 80px * ${scale} + 32px * ${scale})`,
                        paddingRight: 100 * scale,
                    }}
                >
                    {responseCounts.map((count, index) =>
                        <ResponseCount
                            label={index} count={count}
                            visualPercent={
                                isNaN(responses.length > 0
                                    ? Math.round(count / Math.max(...responseCounts) * 100)
                                    : 0)
                                ? 0
                                : (responses.length > 0
                                    ? Math.round(count / Math.max(...responseCounts) * 100)
                                    : 0)
                            }
                            percent={
                                responses.length > 0
                                    ? Math.round(count / responses.length * 100)
                                    : 0
                            }
                            key={index}
                            visible={visible}
                        />
                    )}
                </div>
            </div>
        </Card>
    </>
}

export default ResponseCountList