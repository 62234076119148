import { useContext, useState } from "react"
import Text from "../components/text/Text"
import { useTheme } from "../Themes"
import Button, { ButtonThemes } from "../components/Button"
import { getAuth } from "firebase/auth"
import { signInWithEmailAndPassword } from "firebase/auth"
import Input from "../components/Input"
import { ScaleContext } from "../utils/Scale"
import Squiggle from "../images/SquiggleAccent50.png"

const SignIn = ({ onContinue, viewport }) => {
    const theme = useTheme()
    const auth = getAuth()
    const [username, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [signingIn, setSigningIn] = useState(false)
    const [error, setError] = useState(false)
    const { scale } = useContext(ScaleContext)

    return <>
        <img src={Squiggle} style={{
            mixBlendMode: 'screen',
            position: 'absolute',
            top: -116 * viewport.scale, left: viewport.width / 2 - 723 * viewport.scale * 0.75 / 2,
            width: 723 * viewport.scale, height: 1304 * viewport.scale,
            zIndex: -1
        }} />
        <div
            style={{ width: '100%' }}
        >
            <Text style={{ color: theme.white, margin: 0, marginTop: 70 * scale, fontSize: 25 * scale, fontWeight: 700, lineHeight: '120%' }}>Sign in</Text>
            <Text style={{ color: theme.white, margin: 0, marginTop: 6 * scale, marginBottom: 50 * scale, fontSize: 13 * scale, fontWeight: 600, lineHeight: '150%' }}>Please enter your Ponder login details</Text>
        </div>
        <Input value={username} onChange={setUsername} label={'Username'} placeholder={'Enter your username'} theme={theme} style={{ marginBottom: 10 * scale }} />
        <Input value={password} onChange={setPassword} label={'Password'} placeholder={'Enter your password'} theme={theme} style={{ marginBottom: error ? 15 * scale : 49 * scale }} type={"password"} />
        {error && <Text style={{ color: theme.error, margin: 0, marginBottom: 15 * scale, fontSize: 13 * scale }}>Sign in has failed, please try again.</Text>}
        <Button
            loading={signingIn}
            loadingMessage='Signing In...'
            theme={ButtonThemes.Light}
            onClick={() => {
                setSigningIn(true)
                setError(false)
                signInWithEmailAndPassword(
                    auth,
                    username + "@quandary.app",
                    password
                ).then(userCredential => {
                    onContinue()
                    setSigningIn(false)
                }).catch(err => {
                    setPassword('')
                    setSigningIn(false)
                    setError(true)
                })
            }}
        >
            Continue
        </Button>
    </>
}

export default SignIn